import React, { useEffect } from "react";
import moment from "moment";
import { InferType } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import Text from "src/components/ui/Text";
import Label from "src/components/ui/Label";
import AppSelect from "src/components/form/AppSelect";
import AppDatePicker from "src/components/form/AppDatePicker";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

import { InvoiceTypes, PaymentStatus } from "src/api/graphql/types-and-hooks";

import { getInvoicesPath } from "src/common/router/routerPaths";

import { addPrices } from "src/utils/currency";

import loanSchema from "./loan.schema";
import { useCreateInvoice, useEditInvoice } from "../invoice.hooks";

type Props = {
  formValues?: InferType<typeof loanSchema>;
  startAccountValuesDate?: Date;
};

const LoanForm: React.FC<Props> = ({ formValues = {}, startAccountValuesDate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { invoiceId } = useParams();
  const { isUpdating, handleEditInvoice } = useEditInvoice(invoiceId);
  const { isCreating, handleCreateInvoice } = useCreateInvoice();

  const {
    watch,
    control,
    register,
    setValue,
    setError,
    getValues,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({
    values: { ...formValues, paymentStatus: invoiceId ? formValues?.paymentStatus : PaymentStatus.Unpaid },
    resolver: yupResolver(loanSchema),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (watch("paymentStatus") === PaymentStatus.Paid) {
      setValue("paymentDate", null, { shouldDirty: false });
    } else {
      setValue("paymentDate", new Date(), { shouldDirty: true });
    }
  }, [watch("paymentStatus")]);

  const parseData = (data: any) => {
    if (data.paymentDue && moment(data.paymentDue).isSameOrBefore(moment(startAccountValuesDate))) {
      setError("paymentDue", {
        type: "manual",
        message: "Payment-due-start-account-values-date-error",
      });
      return;
    }

    const total = addPrices([data.capital, data.interest]);
    const subtotal = total;
    const leftToPay = data.paymentStatus === PaymentStatus.Paid ? 0 : total;
    const vat = 0;

    if (invoiceId) {
      handleEditInvoice({
        ...data,
        total,
        subtotal,
        vat,
        leftToPay,
        id: invoiceId,
      });
    } else {
      delete data.paymentDate;
      handleCreateInvoice({
        ...data,
        total,
        subtotal,
        vat,
        leftToPay,
        issueDate: moment().startOf("day").toDate(),
      });
    }
  };

  return (
    <div className="bg-white rounded-[20px] h-full p-6">
      <div className="flex flex-row 2xl:space-x-8 xl:space-x-6 space-x-4 pb-4 items-center border-b">
        <div className="flex flex-col space-y-1 basis-1/6 h-full">
          <Label>{t("Number")}</Label>
          <AppInput
            id="invoiceNumber"
            register={register}
            inputType={InputTypes.TEXT}
            placeholder={`${t("Number")}/${t("Loan-name")}`}
            inputClasses="font-sf-pro-medium border border-bg-light-gray3"
            error={!!formErrors.invoiceNumber}
          />
        </div>
        <div className="flex flex-col space-y-1 basis-1/6 h-full">
          <Label>{t("Payment-status")}</Label>
          {!invoiceId && (
            <Text className="2xl:text-14 xl:text-12 text-10 text-brand-primary">{t("Payment-status-change-info")}</Text>
          )}
          <Controller
            control={control}
            name="paymentStatus"
            render={({ field }) => (
              <AppSelect
                field={field}
                placeholder="Status płatności"
                options={[
                  { label: t("Paid"), value: PaymentStatus.Paid },
                  { label: t("Unpaid"), value: PaymentStatus.Unpaid },
                ]}
                error={!!formErrors.paymentStatus}
                disabled={!invoiceId}
              />
            )}
          />
        </div>
        {watch("paymentStatus") === PaymentStatus.Paid ? (
          <div className="flex flex-col space-y-1 basis-1/6 h-full">
            <Label>{t("Payment-date")}</Label>
            <div className="h-full">
              <Controller
                control={control}
                name="paymentDate"
                render={({ field }) => <AppDatePicker field={field} error={!!formErrors?.paymentDate} />}
              />
            </div>
          </div>
        ) : undefined}
        <div className="flex flex-col space-y-1">
          <Label>{t("Type")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">
            {t(getValues("type") === InvoiceTypes.Cost ? "Cost" : "Income")}
          </Text>
        </div>
        <div className="flex flex-col space-y-1">
          <Label>{t("Genre")}</Label>
          <Text className="2xl:text-20 xl:text-18 text-16 font-sf-pro-bold">{t(getValues("genre"))}</Text>
        </div>
      </div>
      <div className="flex flex-col items-center pt-10">
        <Text className="2xl:text-36 xl:text-32 text-28 font-sf-pro-medium">{t("Complete-data")}</Text>
        <div className="flex flex-row space-x-4 mt-4">
          <div className="flex flex-col space-y-1">
            <Label>{t("Capital")}</Label>
            <AppInput
              id=".capital"
              register={register}
              inputType={InputTypes.TEXT}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.capital}
              disabled={getValues("paymentStatus") === PaymentStatus.Paid && !!invoiceId}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <Label>{t("Interest")}</Label>
            <AppInput
              id="interest"
              register={register}
              inputType={InputTypes.TEXT}
              inputClasses="font-sf-pro-medium border border-bg-light-gray3"
              error={!!formErrors.interest}
              disabled={getValues("paymentStatus") === PaymentStatus.Paid && !!invoiceId}
            />
          </div>
          <div
            className={`flex flex-col space-y-1 ${
              formErrors?.paymentDue?.message && formErrors.paymentDue.message !== "paymentDue is a required field"
                ? "mb-4"
                : ""
            }`}
          >
            <Label>{t("Due-date")}</Label>
            <div className="h-full">
              <Controller
                control={control}
                name="paymentDue"
                render={({ field }) => (
                  <AppDatePicker
                    field={field}
                    error={!!formErrors?.paymentDue}
                    errorMessage={
                      formErrors?.paymentDue?.message &&
                      formErrors.paymentDue.message !== "paymentDue is a required field"
                        ? t(formErrors?.paymentDue?.message)
                        : null
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row space-x-4 2xl:mt-12 xl:mt-10 mt-8 items-center">
          <div className="min-w-[100px]">
            <AppButton
              color={ButtonColorTypes.PRIMARY}
              title={t("Save")}
              customClass="rounded-[8px]"
              textClass="text-white"
              onClick={handleSubmit(parseData)}
              isLoading={isCreating || isUpdating}
            />
          </div>
          <div>
            <AppButton
              color={ButtonColorTypes.SECONDARY}
              title={t("Cancel")}
              textClass="text-text-secondary"
              onClick={() => navigate(getInvoicesPath())}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanForm;
